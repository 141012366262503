import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import {Switch} from "react-router"
import Navbar from "./components/Navbar";
import Logo from "./components/Logo";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
// import Contact from "./components/pages/Contact";
import Services from "./components/pages/Services.js";
import { Helmet } from "react-helmet";
import Contact from "./components/Contact.js";

function App() {
  return (
    <>
      <Helmet>
        <title>svrent homepage</title>
        <meta
          name="Aglobygg homepage "
          content="städfirma , städfirma stockholm pris,storstädning,städning, städa, städhjälp, städer i sverige ,
          städ stockholm , städ hemma, städ åhus, cleaning, städ danderyd, städfirma salem, städer i sverige, 
          städ täby, städ norrtälje,städ ekerö,städ eskilstuna,städ enköping,städ flytt,städföretag,  
          städ flytt stockholm,städ f,städ i stockholm, städ i hemmet,städ järfälla,städ julkalender,städ köpa,
           städ bjärnum,städ bromma,städ alla bolag,städ lidingö,städmopp,städmaterial, 
            städ och tvätt,städ och service,städer i, städ pris,städ rut,
             städ södertälje,  städ sollentuna,städ upplands väsby,städ uppsala,städfirma haninge,
             städfirma huddinge,städfirma täby, städfirma solna,städfirma skåne,städfirma söderhamn,städfirma katrineholm,
             flytt firma,flytt städ,flytt och städ,stad i ljus,storstäd,restaurang storstad,flyttstädning,
             flyttstädning stockholm,flyttstädning priser,flyttstädning pris,flyttstädning checklista,
             vad kostar flyttstädning,flyttstädning södertälje,flyttstädning vad ingår,boka flyttstädning, 
             flyttstädning kostnad,flyttstädning solna,billig flyttstädning,flyttstädning huddinge,flyttstädning haninge,
             kontorsstäd,kontorsstäd stockholm,fönsterputs,fönsterputs stockholm,fönsterputs pris,barnpassning,
             barnpassning stockholm,barnpassning pris,barnpassning nära dig,rutavdrag barnpassning,FÖRETAGSTJÄNSTER,
              FÖNSTERPUTS, FLEXIBEL TRAPP OCH ENTRE-STÄDNING , GARANTERAT NÖJD,veckostädning pris,
              dry cleaning, cleaning,steam cleaning, cleaning house."
          data-react-helmet="true"
        ></meta>
      </Helmet>
      <Router>
        <Logo />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
