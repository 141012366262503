import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./CardServices.css";
import "bootstrap/dist/css/bootstrap.min.css";

function CardServices() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div id="services-cards" className=" cardservices3-box">
      <div className="wrapper">
        <h1 className="title">VÅRA TJÄNSTER OCH PRISER</h1>
        <div className="row">
          <div
            id="Veckostadning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/clean1.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p className="first-name">Veckostädning</p>
                  <span>350 kr/tim +moms (219 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    {" "}
                    <u>Alla rum och hallen</u> <br />
                    • Dammsuger golv, lister, elkontakter, element, mattor samt
                    klädda och stoppade möbler
                    <br />
                    • Fuktmoppar golv eller rengör dem på annat sätt enligt
                    överenskommelse
                    <br />
                    • Torkar dörrar och karmar där vi kommer åt
                    <br />
                    • Dammtorkar fönsterbrädor och fria ytor
                    <br />
                    • Dammtorkar lampor, hyllor och tavelramar där vi kommer åt
                    <br />
                    • Putsar speglar
                    <br />
                    • Bäddar sängar
                    <br />
                    • Tömmer papperskorgar
                    <br />
                    <u>Badrum och toalett</u>
                    <br />
                    • Rengör badkar, dusch, handfat och toalett
                    <br />
                    • Torkar bort ﬂäckar från väggar
                    <br />
                    • Torkar av badrumsskåp och vitvaror
                    <br />
                    <u>Kök</u>
                    <br />
                    • Rengör disk- och köksbänk, spis och vägg bakom spis och
                    diskbänk
                    <br />
                    • Rengör inuti mikrovågsugnen
                    <br />
                    • Rengör ut- och invändigt i skåp där sopbehållare ﬁnns
                    <br />
                    • Torkar av köksluckor
                    <br />
                    • Torkar av ﬂäkt och utsidan av övriga vitvaror • Tömmer
                    hushållssoporna
                    <br />
                    Du kan lägga till timmar i din veckostädning för ytterligare
                    moment, t ex tvätt och strykning, kyl- och ugnsrengöring,
                    organisering av garderober och av barnens leksaker.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Stadning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/clean3.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>
                    Städning utanför Stockholm <br />* 60 km från Stockholm
                  </p>
                  <span>moms (231,25 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    {" "}
                    <u>Alla rum och hallen</u> <br />
                    • Dammsuger golv, lister, elkontakter, element, mattor samt
                    klädda och stoppade möbler
                    <br />
                    • Fuktmoppar golv eller rengör dem på annat sätt enligt
                    överenskommelse
                    <br />
                    • Torkar dörrar och karmar där vi kommer åt
                    <br />
                    • Dammtorkar fönsterbrädor och fria ytor
                    <br />
                    • Dammtorkar lampor, hyllor och tavelramar där vi kommer åt
                    <br />
                    • Putsar speglar
                    <br />
                    • Bäddar sängar
                    <br />
                    • Tömmer papperskorgar
                    <br />
                    <u>Badrum och toalett</u>
                    <br />
                    • Rengör badkar, dusch, handfat och toalett
                    <br />
                    • Torkar bort ﬂäckar från väggar
                    <br />
                    • Torkar av badrumsskåp och vitvaror
                    <br />
                    <u>Kök</u>
                    <br />
                    • Rengör disk- och köksbänk, spis och vägg bakom spis och
                    diskbänk
                    <br />
                    • Rengör inuti mikrovågsugnen
                    <br />
                    • Rengör ut- och invändigt i skåp där sopbehållare ﬁnns
                    <br />
                    • Torkar av köksluckor
                    <br />
                    • Torkar av ﬂäkt och utsidan av övriga vitvaror • Tömmer
                    hushållssoporna
                    <br />
                    Du kan lägga till timmar i din veckostädning för ytterligare
                    moment, t ex tvätt och strykning, kyl- och ugnsrengöring,
                    organisering av garderober och av barnens leksaker.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Flyttstadning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/clean7.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Flyttstädning</p>
                  <span>510 kr/tim +moms (319 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    <u>I alla rum</u>
                    <br />
                    • Putsar fönster.
                    <br />
                    • Dammsuger och våttorkar snickerier, lister, dörrar,
                    dörrkarmar, fönsterbrädor, fria ytor, garderobsdörrar och
                    skåpdörrar.
                    <br />
                    • Torkar ovanpå och invändigt i garderober, skåp, och
                    bokhyllor.
                    <br />
                    • Torkar i golvskenor på skjutdörrar.
                    <br />
                    • Dammsuger och våttorkar element.
                    <br />
                    • Dammtorkar väggar.
                    <br />
                    • Dammtorkar eluttag.
                    <br />
                    • Dammtorkar lampor.
                    <br />
                    • Putsar speglar. • Tar bort aska från kakelugn och öppen
                    spis.
                    <br />
                    • Torkar ytterdörrar.
                    <br />
                    • Dammsuger och moppar golv.
                    <br />
                    <u> I köket gör vi även</u>
                    <br />
                    • Rengör kyl och frys ut- och invändigt.
                    <br />
                    • Rengör spis och ugn ut- och invändigt, även plåtar och
                    galler.
                    <br />
                    • Rengör fläkt och fläktfilter (ej kolfilter).
                    <br />
                    • Rengör in- och utvändigt samt ovanpå skåp, hyllor och
                    lådor.
                    <br />
                    • Rengör kakel/stänkskydd ovanför diskbänk.
                    <br />
                    • Rengör under skåp och torkar av socklar.
                    <br />
                    • Rengör mikrovågsugn ut- och invändigt.
                    <br />
                    • Torkar diskmaskin in- och utvändigt.
                    <br />
                    • Torkar ur besticklådor.
                    <br />
                    • Tömmer och torkar sopkorg.
                    <br />
                    • Putsar diskho, blandare, propp och sil.
                    <br />
                    <u>I badrum och på toaletter</u>
                    <br />
                    • Rengör väggar och golv.
                    <br />
                    • Putsar blandare, synliga rör och duschmunstycke.
                    <br />
                    • Avfettar och avkalkar väggar och fogar där det är möjligt.
                    <br />
                    • Rengör golvbrunn.
                    <br />
                    • Rengör hela duschen alt. badkaret, tar bort fronten och
                    torkar under.
                    <br />
                    • Torkar in- och utsida samt ovanpå badrumsskåp.
                    <br />
                    • Rengör kran och handfat.
                    <br />
                    • Rengör hela toalettstolen.
                    <br />
                    • Rengör utsidan av vitvaror samt i tvättmedelsbehållaren på
                    tvättmaskinen.
                    <br />
                    <u> hallen</u>
                    <br />• Dammtorkar ledstång, räcke och spjälor i trappa. •
                    Torkar hatthylla. • Torkar proppskåp
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Storstadning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/cleaning4.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Storstädning</p>
                  <span>380 kr/tim +moms (237 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    {" "}
                    <u>I alla rum och hallen</u>
                    <br />
                    • Dammtorkar väggar • Dammsuger och våttorkar snickerier,
                    element, fria ytor samt ovanpå garderober och skåp. •
                    Dammtorkar prydnadssaker, hyllor och tavlor.
                    <br />
                    • Dammtorkar eluttag och kontakter. • Dammsuger stoppade
                    möbler. • Dammsuger på och under mattor (om möjligt).
                    <br />
                    • Dammtorkar lampor. • Torrdammar elektronik (ej på
                    bildskärm). • Putsar speglar. • Tömmer papperskorgar.
                    <br />
                    • Dammsuger och våttorkar golv. • Bäddar sängar. • Bäddar
                    rent om nya lakan ligger framme. • Dammtorkar eller
                    dammsuger sänggavel/-karm
                    <br />
                    • Dammtorkar ledstång, räcke och spjälor i trappa. •
                    Dammsuger och torkar av skoställ och hatthylla.
                    <br />
                    <u>Badrum och toalett Alla generella momenten och...</u>
                    <br />
                    • Torkar av synliga rör. • Torkar väggar och golv. • Putsar
                    blandare, kran, munstrycke och rör i dusch.
                    <br />
                    • Avfettar och avkalkar väggar och fogar i duschutrymme om
                    det är möjligt. • Rengör badkar, tar bort front och torkar
                    under. • Rengör golvbrunn. • Torkar av utsida och ovanpå
                    badrumsskåp.
                    <br />
                    • Rengör kran och handfat. • Rengör hela toalettstolen.
                    <br />
                    • Rengör utsidan av vitvaror samt i tvättmedelsbehållaren på
                    tvättmaskinen. • Rengör filtret i torktumlaren. • Torkar av
                    flaskor som står framme.
                    <br />
                    <u>Kök Alla generella momenten och ...</u>
                    <br />
                    • Rengör fläkt och fläktfilter (ej kolfilter). • Rengör
                    ovanpå skåp och hyllor. • Torkar köksluckor ut- och
                    invändigt.
                    <br />
                    • Rengör kyl och frys utvändigt. • Rengör kakel/stänkskydd
                    ovanför diskbänk. • Rengör under skåp (om möjligt) och
                    torkar av socklar. • Rengör mikrovågsugn ut- och invändigt.
                    • Torkar diskmaskin ut- och invändigt. • Torkar av det som
                    står framme t.ex. kaffemaskin, oljor, salt och peppar. •
                    Torkar ur besticklådor. • Rengör ut- och invändigt i skåp
                    där sopbehållare finns. • Tömmer sopor och torkar sopkorg. •
                    Putsar diskho, blandare, propp och sil
                    <br />
                    <u>Du kan även boka</u>
                    <br />• Fönsterputs • Invändig ugnsrengöring • Invändig
                    rengöring av kylskåp och frys • Invändig rengöring av
                    köksskåp och lådor • Strykning
                    <u>Fönsterputs</u>
                    <br />• Ange om du vill ha fönsterkarmarna tvättade också så
                    lägger vi extra tid.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Kontorsstad"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/cleaning5.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Företagsstäd</p>
                  <span>380 kr/tim +moms</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    {" "}
                    <u>I alla rum</u>
                    <br />• Dammtorkar alla åtkomliga ytor såsom kontorsplatser,
                    bordslampor, armaturer, hyllor, fönsterbleck etc • Dammsuger
                    golv, lister, eluttag och mattor • Moppar alla hårda golv •
                    Tömmer papperskorgar • Tar bort fläckar på dörrar,
                    glaspartier och whiteboards • Putsar speglar • Ställer i
                    ordning stolar i mötesrum och på kontorsplatser, torkar av
                    vid behov
                    <u>Kök</u>
                    <br />
                    • Dammtorkar och rengör alla öppna ytor
                    <br />
                    • Tar bort fläckar på köksluckor, bord, stolar och övriga
                    vitvaror på utsidan
                    <br />
                    • Rengör in- och utvändigt där sopbehållare finnes
                    <br />
                    • Rengör in- och utvändigt mikrovågsugn
                    <br />
                    • Rengör kaffemaskinen/automaten
                    <br />
                    • Sätter igång/tömmer diskmaskin alternativt diskar den disk
                    som finnes
                    <br />
                    • Fyller på med handtvål, diskmedel och pappershanddukar
                    <br />
                    • Byter ut disktrasa och diskborste vid behov
                    <br />
                    • Tömmer och slänger sopor
                    <br />
                    • Dammsuger och moppar golvet
                    <br />
                    <u>Toalett och duschrum</u>
                    <br />
                    • Dammtorkar och rengör hängare, hållare och badrumsskåp
                    <br />
                    • Rengör handfat, kran, toalett samt dusch om detta finnes
                    <br />
                    • Torkar av tvättmaskin och torktumlare
                    <br />
                    • Putsar spegel
                    <br />
                    • Tömmer papperskorgar
                    <br />
                    • Fyller på handtvål, toapapper och pappershanddukar
                    <br />
                    • Dammsuger och moppar golvet
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Fonsterputs"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/cleaning6.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Fönsterputs</p>
                  <span>370 kr/tim +moms ( 231.25 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text"></p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Barnpassning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/cleaning7.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Barnpassning</p>
                  <span>380 kr/tim +moms (237,5 kr/tim efter RUT)</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text"></p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Entrestadning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/cleaninghero.png"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p className="first-name">Entréstädning</p>
                  <span>200 kr/tim efter RUT-AVDRAG</span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text"></p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Barnpassning"
            className="col-12  col-md-6 col-lg-4  d-flex justify-content-between mb-5"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div className="container">
              <div className="front">
                {" "}
                <img
                  className="services-2"
                  src="../images/vaccum1.jpg"
                  path="/about"
                  alt=""
                />
                <div className="inner">
                  <p>Vi jobbar med RUT-avdrag </p>
                  <span></span>
                </div>
              </div>
              <div className="back">
                <div className="inner">
                  <p className="text">
                    <a
                      href="https://www.skatteverket.se/privat/fastigheterochbostad/rotochrutarbete.4.2e56d4ba1202f95012080002966.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here for more information{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardServices;
