import React from "react";
import "./Footer.css";
import "bootstrap/dist/css/bootstrap.css";

function Footer() {
  return (
    <div className="footer-container" id="contact">
      <div className="container">
        <div className="row w-100 justify-content-between align-items-center">
          {/* Logo Column */}
          <div className="col-md-4 footer-col text-center">
            <img
              className="img_logo"
              src="../images/cl2.jpg"
              alt="Company Logo"
            />
          </div>

          {/* Address Column */}
          <div className="col-md-4 footer-col text-center">
            <div className="address">
              <strong>Adress huvudkontor:</strong>
              <br />
              Runby Tog 9, 19446 Upplands Väsby
            </div>
            <br />
            <div className="contact-icons">
              <i className="fas fa-mobile-alt"></i> +46 793 353 821
            </div>
            <div className="contact-icons">
              <i className="far fa-envelope"></i> info@svrent.se
            </div>
          </div>
        </div>
      </div>

      {/* Last Line - Unchanged */}
      <div className="website-rights">
        <a
          href="https://samoteev.dev/"
          className="nextek"
          target="_blank"
          rel="noreferrer"
        >
          Digital Magic by AS
        </a>
        © 2025
      </div>
    </div>
  );
}

export default Footer;
