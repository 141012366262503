import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-icons/fa";
import "./Navbar.css";
import { navItems } from "./NavItems";

function Navbar() {
  const [mobile, setMobile] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1100) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setMobile(true);
      } else {
        setMobile(false);
        setSidebar(false);
      }
    };
    const changeBackground = () => {
      if (window.scrollY >= 100) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener("scroll", changeBackground);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="navbar-container">
        <nav className={navbar ? "navbar active" : "navbar"}>
          <div>
            {" "}
            <Link
              to="/"
              className="navbar-logo"
              onClick={() => setSidebar(false)}
            ></Link>
          </div>
          {!mobile && (
            <ul className="nav-items">
              {navItems.map((item) => {
                return (
                  <li key={item.id} className={item.nName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {/* {!mobile && <Button />} */}

          {mobile && (
            <div className="sidebar-toggle">
              {sidebar ? (
                <Icons.FaTimes
                  className="sidebar-toggle-logo"
                  onClick={() => setSidebar(!sidebar)}
                />
              ) : (
                <Icons.FaBars
                  className="sidebar-toggle-logo"
                  onClick={() => setSidebar(!sidebar)}
                />
              )}
            </div>
          )}
        </nav>

        <div className={sidebar ? "sidebar active" : "sidebar"}>
          <ul className="sidebar-items">
            {navItems.map((item) => {
              return (
                <li
                  key={item.id}
                  className={item.sName}
                  onClick={() => setSidebar(false)}
                >
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
